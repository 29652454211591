body {
    font-size: 17px;
}

a {
    @apply font-semibold;
    @apply underline;
    @apply decoration-dashed;
    @apply underline-offset-2;
    @apply text-main-700;
    @apply hover:text-main-500;
    @apply hover:decoration-solid;
    @apply transition-all;
    @apply duration-500;
}

blockquote {
    @apply pl-6;
    @apply my-8;
    @apply text-lg;
    @apply font-normal;
    @apply italic;
    @apply text-slate-800;
    @apply border-l-4;
    @apply border-main-400;
}

code {
    @apply text-xs;
}

pre {
    @apply text-slate-100;
    font-size: 0.85rem !important;
    @apply border;
    @apply border-main-600;
    @apply rounded;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    @apply mt-8;
    @apply mb-4;
    @apply text-slate-900;
    @apply leading-tight;
    @apply font-sanslight;

    &:first-child {
        @apply mt-0;
    }
}

h1 {
    @apply text-5xl;
    @apply font-extrabold;
}

h2 {
    @apply text-4xl;
    @apply font-bold;
}

h3 {
    @apply text-3xl;
    @apply font-bold;
}

h4 {
    @apply text-2xl;
    @apply font-normal;
}

h5 {
    @apply text-xl;
    @apply font-normal;
}

h6 {
    @apply text-lg;
    @apply font-light;
}

hr {
    @apply my-12;
    @apply border-b;
    @apply border-main-200;
    @apply rounded-full;
}

li {

    & ul,
    & ol {
        @apply ml-2.5;
        @apply my-0;
        @apply border-l-2;
    }
}

ol,
ul {
    @apply my-1;
}

ol {
    @apply list-decimal;
}

ul {
    @apply list-disc;
}

p {
    @apply my-3;

    @screen md {
        @apply my-6;
    }
}

/*pre {
    @apply p-4;
    @apply my-6;
    @apply text-xs;
    @apply bg-slate-700;
    @apply leading-loose;
    @apply rounded;
    @apply shadow;
    @apply overflow-x-auto;

    & code {
        @apply relative;
        @apply block;
        @apply p-0;
        @apply bg-transparent;
    }
}*/

::selection {
    @apply text-white;
    @apply bg-main-400;
}

.button-main {
    @apply flex;
    @apply w-full;
    @apply justify-center;
    @apply rounded-md;
    @apply border;
    @apply border-transparent;
    @apply bg-main-600;
    @apply py-2;
    @apply px-4;
    @apply text-sm;
    @apply font-medium;
    @apply text-white;
    @apply hover:bg-main-700;
    @apply hover:text-main-50;
    @apply focus:outline-none;
    @apply focus:ring-2;
    @apply focus:ring-main-500;
    @apply focus:ring-offset-2;
    @apply transition-all;
    @apply duration-500;
    @apply ease-out;
}

input {
    @apply mt-1;
    @apply block;
    @apply w-full;
    @apply rounded-md;
    @apply border;
    @apply border-slate-300;
    @apply py-2;
    @apply px-3;
    @apply shadow-sm;
    @apply focus:border-main-500;
    @apply focus:outline-none;
    @apply focus:ring-main-500;
    @apply sm:text-sm;
}

select {
    @apply mt-1;
    @apply block;
    @apply w-full;
    @apply rounded-md;
    @apply border;
    @apply border-slate-300;
    @apply py-2;
    @apply pl-3;
    @apply pr-10;
    @apply shadow-sm;
    @apply focus:border-main-500;
    @apply focus:outline-none;
    @apply focus:ring-main-500;
    @apply sm:text-sm;
}


label {
    @apply block;
    @apply text-sm;
    @apply font-medium;
    @apply text-slate-700;
}

.tab-item{
    @apply whitespace-nowrap;
    @apply py-4;
    @apply px-1;
    @apply border-b-2;
    @apply font-medium;
    @apply text-sm;
    @apply no-underline;
    @apply text-slate-500;
}
.tab-inactive{
    @apply border-transparent;
    @apply text-slate-500;
    @apply hover:text-slate-700;
    @apply hover:border-slate-300;
}

.tab-active{
    @apply border-main-500;
    @apply text-main-600;
}

.api-dl, .command-options{
    @apply grid;
    @apply grid-cols-1;
    @apply lg:grid-cols-5;
    @apply gap-4;
    @apply my-3;
    @apply items-center;
}

.api-dt{
    @apply col-span-1;
}

.api-dd,{
    @apply col-span-1;
    @apply lg:col-span-4;
}

.figcap{
    @apply text-xs;
    @apply text-center;
    @apply lg:text-right;
    @apply text-slate-400;
    @apply lg:pr-3;
}

.command-options{
    @apply gap-0;
    @apply my-0;
}

.command-dt{
    @apply col-span-2;
    @apply py-2;
    @apply bg-main-500;
    @apply pl-3;
}

.command-dd{
    @apply col-span-1;
    @apply lg:col-span-3;
    @apply py-2;
    @apply bg-main-600;
    @apply pl-3;
    @apply text-main-100;
}

.command-header{
    @apply font-mono;
    @apply text-2xl;
    @apply bg-main-900;
    @apply pl-3;
    @apply my-0;
    @apply text-main-100;
}

.command-options{
    @apply text-base;
}

.command-description{
    @apply text-base;
}